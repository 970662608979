.salary-detail[data-v-a4ec150c] {
  display: flex;
  flex-direction: column;
}
.salary-detail .search-box[data-v-a4ec150c] {
    padding: 30px 0 8px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.salary-detail-content[data-v-a4ec150c] {
    flex: 1;
}
